import { Replacer } from "./replacer";
import FadeIn from "../fadeIn";

class ReplaceCommon {
  private replacer: Replacer;
  private fadeIn: FadeIn | undefined;
  
  constructor(replacer: Replacer, fadeIn?: FadeIn) {
    this.replacer = replacer
    this.fadeIn = fadeIn
  }
  
  replace({ data, startOpacity }: IProps) {
    this.backwardCompatibility({ data, startOpacity })
    this.replaceText({ data, startOpacity })
    this.replaceImage({ data, startOpacity })
    this.replaceComplex({ data, startOpacity })
  }
  
  backwardCompatibility(props: { data: IData, startOpacity: number }) {
    const { data, startOpacity } = props
    const smoothAppearance = this.fadeIn?.smoothAppearance
    const {
      teams,
      organizerLogo,
      goals,
      fouls,
    } = data
    
    this.replacer.replaceImageByIndicator({
      indicator: 'teamLogo0',
      url: teams?.first?.logo ?? '',
      options: { opacity: startOpacity, onLoaded: smoothAppearance },
    })
    this.replacer.replaceImageByIndicator({
      indicator: 'teamLogo1',
      url: teams?.second?.logo ?? '',
      options: { opacity: startOpacity, onLoaded: smoothAppearance },
    })
    this.replacer.replaceImageByIndicator({
      indicator: 'organizerLogo',
      url: organizerLogo ?? '',
      options: { opacity: startOpacity, onLoaded: smoothAppearance },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'teamName0',
      text: (teams?.first?.name ?? '').toUpperCase(),
      options: { opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'teamName1',
      text: (teams?.second?.name ?? '').toUpperCase(),
      options: { opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'teamScore0',
      text: goals?.first?.toString() ?? '',
      options: { opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'teamScore1',
      text: goals?.second?.toString() ?? '',
      options: { opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'teamFouls0',
      text: fouls?.first?.toString() ?? '',
      options: { opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'teamFouls1',
      text: fouls?.second?.toString() ?? '',
      options: { opacity: startOpacity },
    })
    
    this.replacer.replaceLastResultGame({
      indicator: 'lastGameResults0',
      results: teams?.first?.lastGamesResults,
      options: { opacity: startOpacity },
    })
    this.replacer.replaceLastResultGame({
      indicator: 'lastGameResults1',
      results: teams?.second?.lastGamesResults,
      options: { opacity: startOpacity },
    })
  }
  
  replaceText(props: { data: IData, startOpacity: number }) {
    const { data, startOpacity } = props
    const {
      teams,
      goals,
      fouls,
      championshipName,
      city,
      formatStartDate,
      timer,
      period,
    } = data
    
    this.replacer.replaceTextByIndicator({
      indicator: 'city',
      text: city ?? '',
      options: { opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'championshipName',
      text: championshipName ?? '',
      options: { opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'startMatchTime',
      text: formatStartDate?.time ?? '',
      options: { opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'startMatchDate',
      text: formatStartDate?.date ?? '',
      options: { opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'matchTime',
      text: timer ?? '',
      options: { opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'period',
      text: period?.toString() ?? '',
      options: { opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'teamName',
      text: (teams?.first?.name ?? '').toUpperCase(),
      options: { opacity: startOpacity, teamIndex: 0 },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'teamName',
      text: (teams?.second?.name ?? '').toUpperCase(),
      options: { opacity: startOpacity, teamIndex: 1 },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'teamScore',
      text: goals?.first?.toString() ?? '',
      options: { opacity: startOpacity, teamIndex: 0 },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'teamScore',
      text: goals?.second?.toString() ?? '',
      options: { opacity: startOpacity, teamIndex: 1 },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'teamFouls',
      text: fouls?.first?.toString() ?? '',
      options: { opacity: startOpacity, teamIndex: 0 },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'teamFouls',
      text: fouls?.second?.toString() ?? '',
      options: { opacity: startOpacity, teamIndex: 1 },
    })
  }
  
  replaceImage(props: { data: IData, startOpacity: number }) {
    const { data, startOpacity } = props
    const smoothAppearance = this.fadeIn?.smoothAppearance
    const {
      teams,
      organizerLogo,
    } = data
    
    this.replacer.replaceImageByIndicator({
        indicator: 'teamLogo',
        url: teams?.first?.logo ?? '',
        options: {
          opacity: startOpacity,
          onLoaded: smoothAppearance,
          teamIndex: 0,
        },
      },
    )
    this.replacer.replaceImageByIndicator({
        indicator: 'teamLogo',
        url: teams?.second?.logo ?? '',
        options: {
          opacity: startOpacity,
          onLoaded: smoothAppearance,
          teamIndex: 1,
        },
      },
    )
    this.replacer.replaceImageByIndicator({
      indicator: 'organizerLogo',
      url: organizerLogo ?? '',
      options: {
        opacity: startOpacity,
        onLoaded: smoothAppearance,
      },
    })
  }
  
  replaceComplex(props: { data: IData, startOpacity: number }) {
    const { data, startOpacity } = props
    const smoothAppearance = this.fadeIn?.smoothAppearance
    const { teams } = data
    const avatar = 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png';
    
    this.replacer.replaceLastResultGame({
      indicator: 'lastGameResults',
      results: teams?.first?.lastGamesResults,
      options: { opacity: startOpacity, teamIndex: 0 },
    })
    this.replacer.replaceLastResultGame({
      indicator: 'lastGameResults',
      results: teams?.second?.lastGamesResults,
      options: { opacity: startOpacity, teamIndex: 1 },
    })
    this.replacer.replaceTeamComposition(
      {
        players: teams?.first?.members?.map(player => ({
          name: `${player.name ?? ''} ${player.surname ?? ''}`,
          avatar: player.photo ?? avatar,
          number: player.number,
        })) ?? [],
        options: {
          teamIndex: 0,
          opacity: startOpacity,
          onLoaded: smoothAppearance,
        },
      })
    this.replacer.replaceTeamComposition(
      {
        players: teams?.second?.members?.map(player => ({
          name: `${player.name ?? ''} ${player.surname ?? ''}`,
          avatar: player.photo ?? avatar,
          number: player.number,
        })) ?? [],
        options: {
          teamIndex: 1,
          opacity: startOpacity,
          onLoaded: smoothAppearance,
        },
      })
  }
}


type Member = {
  name: string
  surname?: string
  photo?: string
  number: number
};

type Team = {
  logo?: string
  name?: string
  lastGamesResults?: ('WON' | 'DRAW' | 'LOST')[],
  members?: Member[]
};

export interface IData {
  teams?: {
    first?: Team
    second?: Team
  }
  organizerLogo?: string
  goals?: {
    first?: number
    second?: number
  }
  fouls?: {
    first?: number
    second?: number
  }
  championshipName?: string,
  city?: string,
  formatStartDate?: { date?: string, time?: string },
  timer?: string,
  period?: number,
}

interface IProps {
  data: IData
  startOpacity: number
}

export default ReplaceCommon
