import { Button, EmptyButton, Item, LongDivider, ShortDivider, SubItem } from "./styles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Group } from "fabric/fabric-impl";
import { useSelector } from "react-redux";
import { selectFabricManager } from "../../../../store/editor/selectors";
import Input, { IconWrapper } from "../../elements/input";
import history from "../../../../../ud-ui/components/fabric-canvas/addons/history";
import { fabric } from "fabric";
import { round } from "../../../../domain/utils/helpers";
import SnapToGrid from "../../../../../ud-ui/components/fabric-canvas/addons/snapToGrid";
import layerManager from "../../../../../ud-ui/components/fabric-canvas/addons/layerManager";
import ArrowRightIcon from "../../../../../ud-ui/components/icon/arrow-right-icon";
import ChoosingTeam from "./components/choosingTeam";
import ColorPicker from "../../elements/colorPicker";
import UDText from "../../../../../ud-ui/components/text";
import UDIcon from "../../../../../ud-ui/components/icon";
import alignment from "../../../../../ud-ui/components/fabric-canvas/addons/alignment/alignment";

interface IProps {
  object: Group
}

function ComplexPlaceholder(props: IProps) {
  const manager = useSelector(selectFabricManager)
  const { object } = props
  
  const data = useMemo(() => { return object.data }, [object.data])
  const point = useMemo(() => {
    const { x, y } = object.getPointByOrigin('left', 'top')
    return { x: round(x), y: round(y) }
  }, [object])
  const bounding = useMemo(() => {
    const { width, height } = object.getBoundingRect()
    return { width: round(width), height: round(height) }
  }, [object])
  const name = useMemo(() => {return data?.name ?? data?.indicator}, [data?.indicator, data?.name]);
  
  const [hasTeam, setHasTeam] = useState(data?.hasTeam ?? data?.teamIndex !== undefined ?? false)
  const [position, setPosition] = useState({ x: point.x, y: point.y })
  const [size, setSize] = useState(bounding)
  const [teamIndex, setTeamIndex] = useState(data?.team ?? data?.teamIndex)
  const [backgroundColor, setBackgroundColor] = useState(data?.backgroundColor ?? '#FFFFFF00')
  const [elementColor, setElementColor] = useState(data?.elementColor ?? '#1A1A29')
  const [borderColor, setBorderColor] = useState(data?.borderColor ?? '#404861')
  const [textColor, setTextColor] = useState(data?.textColor ?? '#FFFFFF')
  
  useEffect(() => {
    object.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      bl: true,
      br: true,
      tl: true,
      tr: true,
      mtr: false,
    })
    
    setPosition(point)
    setSize(bounding)
  }, [bounding, object, point]);
  
  useEffect(() => {
    if (!manager) return
    manager.canvas.uniScaleKey = undefined
    
    return () => {
      manager.canvas.uniScaleKey = 'shiftKey'
    };
  }, [manager]);
  
  useEffect(() => {
    setHasTeam(data?.hasTeam ?? data?.teamIndex !== undefined ?? false)
    if (data?.hasTeam) setTeamIndex(data?.team)
  }, [data?.hasTeam, data?.team, data?.teamIndex])
  
  const onFocusField = useCallback(() => {
    manager?.render()
    history.saveState()
  }, [manager])
  
  const onChangePosition = useCallback((x: number, y: number) => {
    object.setPositionByOrigin(new fabric.Point(x, y), 'left', 'top')
    setPosition({ x: round(x), y: round(y) })
    manager?.render()
  }, [manager, object]);
  
  const onChangeSize = useCallback((width: number, height: number) => {
    object.set({ scaleX: width / (object?.width ?? 1), scaleY: height / (object?.height ?? 1) })
    setSize({ width: round(width), height: round(height) })
    SnapToGrid.transformAfterScaling(object)
    manager?.render()
  }, [manager, object]);
  
  const onChangeTeam = useCallback((teamIndex: 0 | 1) => {
    const oldTeamIndex = object.data?.teamIndex
    if (oldTeamIndex !== undefined) {
      object.data = { ...object.data, teamIndex }
    } else if (!object.data?.team === undefined) {
      object.data = { ...object.data, team: teamIndex }
    }
    
    history.saveState()
    setTeamIndex(teamIndex)
  }, [object]);
  
  const changeBackgroundColor = useCallback((color: string) => {
    setBackgroundColor(color)
    object.data = { ...object.data, backgroundColor: color }
    const background = object.getObjects('rect')[ 0 ]
    if (background) {
      background.set({ fill: color })
    }
    manager?.render()
    history.saveState()
  }, [manager, object]);
  
  const changeElementColor = useCallback((color: string) => {
    setElementColor(color)
    object.data = { ...object.data, elementColor: color }
    const elements = object.getObjects('group') as Group[ ]
    if (elements.length > 0) {
      for (const element of elements) {
        const subElements = element.getObjects('group') as Group[ ]
        if (subElements.length > 0) {
          for (const subElement of subElements) {
            const rect = subElement.getObjects('rect')[ 0 ]
            if (rect) {
              rect.set({ fill: color })
            }
          }
        }
      }
    }
    manager?.render()
    history.saveState()
  }, [manager, object])
  
  const changeBorderColor = useCallback((color: string) => {
    setBorderColor(color)
    object.data = { ...object.data, borderColor: color }
    const elements = object.getObjects('group') as Group[ ]
    if (elements.length > 0) {
      for (const element of elements) {
        const subElements = element.getObjects('group') as Group[ ]
        if (subElements.length > 0) {
          for (const subElement of subElements) {
            const rect = subElement.getObjects('rect')[ 0 ]
            if (rect) {
              rect.set({ stroke: color })
            }
          }
        }
      }
    }
    manager?.render()
    history.saveState()
  }, [manager, object])
  
  const changeTextColor = useCallback((color: string) => {
    setTextColor(color)
    object.data = { ...object.data, textColor: color }
    const elements = object.getObjects('group') as Group[ ]
    if (elements.length > 0) {
      for (const element of elements) {
        const subElements = element.getObjects('group') as Group[ ]
        if (subElements.length > 0) {
          for (const subElement of subElements) {
            const text = subElement.getObjects('text')[ 0 ]
            if (text) {
              text.set({ fill: color })
            }
          }
        }
      }
    }
    manager?.render()
    history.saveState()
  }, [manager, object])
  
  const showDebugInfo = true
  const isDev = useMemo(() => {
    return process.env.NODE_ENV === 'development'
  }, []);
  
  const onClickAlignmentCenterVertical = () => {
    const newPos = alignment.single.centerVertical(object)
    if (!newPos) return
    setPosition({ x: newPos.left, y: newPos.top })
  }
  
  const onClickAlignmentCenterHorizontal = () => {
    const newPos = alignment.single.centerHorizontal(object)
    if (!newPos) return
    setPosition({ x: newPos.left, y: newPos.top })
  }
  
  return (
    <>
      <Item>
        <div>Плейсхолдер</div>
      </Item>
      <ShortDivider/>
      <Item>
        <SubItem style={{ width: '26%', justifyContent: 'space-between' }}>
          <EmptyButton onClick={onClickAlignmentCenterHorizontal}>
            <UDIcon name={'align-center-horizontal'}/>
          </EmptyButton>
          <EmptyButton onClick={onClickAlignmentCenterVertical}>
            <UDIcon name={'align-center-vertical'}/>
          </EmptyButton>
        </SubItem>
        <SubItem>
          <span>Расположение</span>
          <Input
            onFocus={onFocusField}
            style={{ width: 73 }}
            icon={<IconWrapper>X</IconWrapper>}
            value={position.x.toString()}
            onChange={(value) => onChangePosition(+value, position.y)}
          />
          <Input
            onFocus={onFocusField}
            style={{ width: 73 }}
            icon={<IconWrapper>Y</IconWrapper>}
            value={position.y.toString()}
            onChange={(value) => onChangePosition(position.x, +value)}
          />
        </SubItem>
        <SubItem>
          <span>Размер</span>
          <Input
            onFocus={onFocusField}
            icon={<IconWrapper>Ш</IconWrapper>}
            value={size.width.toString()}
            onChange={(value) => onChangeSize(+value, size.height)}
          />
          <Input
            onFocus={onFocusField}
            icon={<IconWrapper>В</IconWrapper>}
            value={size.height.toString()}
            onChange={(value) => onChangeSize(size.width, +value)}
          />
        </SubItem>
      </Item>
      {hasTeam && (
        <>
          <LongDivider/>
          <Item>
            <ChoosingTeam onChange={onChangeTeam} value={teamIndex}/>
          </Item>
        </>
      )}
      {name === 'teamComposition' && (
        <>
          <LongDivider/>
          <Item>
            <UDText type={'caption-1'}>Цвет фона</UDText>
            <ColorPicker color={backgroundColor} onChange={changeBackgroundColor}/>
            <UDText type={'caption-1'}>Цвет элемента</UDText>
            <ColorPicker color={elementColor} onChange={changeElementColor}/>
            <UDText type={'caption-1'}>Цвет рамки</UDText>
            <ColorPicker color={borderColor} onChange={changeBorderColor}/>
            <UDText type={'caption-1'}>Цвет текста</UDText>
            <ColorPicker color={textColor} onChange={changeTextColor}/>
          </Item>
        </>
      )}
      <LongDivider/>
      <Item>
        Слои
        <Button onClick={() => layerManager.sendToFront(object)}>
          <ArrowRightIcon height={16} width={16} color={'#929292'} direction={'up'}/> Поднять вверх
        </Button>
        <Button onClick={() => layerManager.sendToBack(object)}>
          <ArrowRightIcon height={16} width={16} color={'#929292'} direction={'down'}/> Переместить вниз
        </Button>
      </Item>
      {isDev && showDebugInfo && (
        <Item>
          hasTeam: {hasTeam ? 'true' : 'false'},
          <br/>
          data: <span style={{ whiteSpace: 'pre' }}>{JSON.stringify(object.data, null, 2)}</span>
        </Item>
      )}
    </>
  );
}

export default ComplexPlaceholder;
