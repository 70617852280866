import { Placeholder } from "../../../../../../ud-ui/components/fabric-canvas/types/placeholders/createArgument";

interface IItem extends Placeholder {
  listName: string
}

export const complexPlaceholdersList: IItem[] = [
  {
    listName: 'Последние результаты команды',
    indicator: 'lastGameResults',
    blockText: 'Последние результаты\nкоманды',
    type: 'image',
    teamIndex: 0,
  },
  {
    blockText: 'Состав команды',
    indicator: 'teamComposition',
    listName: 'Состав команды',
    type: 'composition',
    teamIndex: 0,
  },
]
