import { RealtimeChannel, SupabaseClient } from '@supabase/supabase-js';
import { ObservableField } from "../ObservableField";
import { NewReceivedData, ReceivedData, ReceivedEvent } from "../../domain/interfaces/supabase/receivedData";
import { Event } from "../../../stream/domain/interfaces/events";

type ConnectionType = "CLOSED" | "SUBSCRIBED" | "TIMED_OUT" | "CHANNEL_ERROR";

type TimeState = {
  seconds: number,
  period: number,
};

export type ScoreTeam = { id: number, score: number };
export type Score = [ScoreTeam, ScoreTeam]

export class MatchChronology {
  constructor(private supabase: SupabaseClient) {}
  
  chanelEvents?: RealtimeChannel
  connectionStatus: ObservableField<ConnectionType> = new ObservableField<ConnectionType>("CLOSED")
  
  lastEvent?: Event
  events: ObservableField<Event[]> = new ObservableField<Event[]>([])
  lastDateUpdate?: Date = undefined
  activeBoardId: ObservableField<number | null> = new ObservableField<number | null>(null)
  score: ObservableField<Score | null> = new ObservableField<Score | null>(null)
  currentTime: ObservableField<TimeState> = new ObservableField<TimeState>({
    seconds: 0,
    period: 0,
  })
  
  public handleRecordUpdated(data: ReceivedData) {
    console.debug({ event: 'new data received', data })
    const date = new Date(data.commit_timestamp)
    if (this.lastDateUpdate === date) return
    this.lastDateUpdate = date
    
    this.checkEvents(data, date)
    this.checkActiveTemplate(data)
    this.checkTime(data)
    this.checkScore(data)
  }
  
  public async observeChanges(matchId: number) {
    this.chanelEvents = this.supabase
      .channel(`room${matchId}`)
      // @ts-ignore
      .on('postgres_changes', {
        event: 'UPDATE',
        schema: 'public',
        table: 'match_chronology',
        filter: `matchId=eq.${matchId}`,
      }, this.handleRecordUpdated.bind(this))
      .subscribe((status, err) => {
        console.debug({ status })
        err && (console.error({ err }))
        this.connectionStatus.value = status
      }, 10000)
  }
  
  public async stopObserveChanges() {
    if (this.chanelEvents) await this.chanelEvents.unsubscribe()
  }
  
  public async getRow(matchId: number): Promise<NewReceivedData[] | null> {
    const { data, error } = await this.supabase
      .from('match_chronology')
      .select()
      .eq('matchId', matchId)
    
    if (!error) return data
    console.error(error)
    return null
  }
  
  private checkEvents(data: ReceivedData, date: Date) {
    const chronology: ReceivedEvent[] = data.new.chronology
    chronology.sort((a, b) => a.id - b.id)
    const lastEvent: ReceivedEvent = chronology[ chronology.length - 1 ]
    if (!lastEvent) return
    if (this.lastEvent && this.lastEvent?.id === lastEvent?.id) return
    const lastEventWithUpdate = { ...lastEvent, updateAt: date.getTime() };
    this.lastEvent = lastEventWithUpdate
    this.events.value = [...this.events.value, lastEventWithUpdate]
  }
  
  private checkActiveTemplate(data: ReceivedData) {
    this.activeBoardId.value = data.new.templateId
  }
  
  private checkTime(data: ReceivedData) {
    const { seconds, currentPeriod: period } = data.new
    this.currentTime.value = {
      ...this.currentTime.value,
      seconds,
      period,
    }
  }
  
  private checkScore(data: ReceivedData) {
    const { firstTeamScore, firstTeamId, secondTeamId, secondTeamScore } = data.new
    this.score.value = [
      { id: firstTeamId, score: firstTeamScore },
      { id: secondTeamId, score: secondTeamScore },
    ]
  }
}
