import { StyledSelect } from "../../../../../template-editor/ui/hooks/modals/board/elements/events-selector/styles";
import styled from "@emotion/styled";
import overridedSelectComponents from "../../../../../ud-form/components/select/component/override";
import { css } from "@emotion/react";
import classNames from "classnames";

export const Control = styled(StyledSelect)`
  background-color: white;
  border: 1px solid #EBEBEB;
`

export const components = {
  ...overridedSelectComponents,
  Control: (props: any) => {
    const isOpen = props.selectProps.menuIsOpen;
    
    return (
      <Control
        {...props.innerProps}
        css={css`
          border: ${isOpen ? '1px solid #F5D956' : '1px solid #EBEBEB'};
          max-width: 370px;

          &:hover {
            border: 1px solid #F5D956;
          }
        `}
        className={classNames('d-flex flex-row pl-4', {
          disabled: props.isDisabled,
          focused: props.isFocused,
          multi: props.isMulti,
          opened: props.menuIsOpen,
        })}
      >
        {props.children}
      </Control>
    );
  },
}
