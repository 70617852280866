import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import useUniversalModal, { IUNModalProps } from "../useUniversalModal";
import SizeInputs from "./elements/size/sizeInputs";
import widthValidator from "../../../../domain/validators/width.validator";
import heightValidator from "../../../../domain/validators/height.validator";
import templateNameOnEditValidator from "../../../../domain/validators/templateNameOnEditValidator";
import useTemplates from "../../useTemplates";
import { updateTemplate } from "../../../../store/templates/actions";
import { resetPressedState } from "../../../../store/templates/templates";

function useEditModal() {
  const dispatch = useDispatch()
  const { activeTemplate, templates, isProcessed, isFinished } = useTemplates()
  
  const validator = useCallback((values: { [ p: string ]: string }) => {
    const errors: { [ p: string ]: string | undefined } = {}
    
    const nameError = templateNameOnEditValidator(values.name?.trim(), templates?.map(category => category.name), activeTemplate?.name)
    if (nameError) {errors.name = nameError}
    const widthError = widthValidator(values.width)
    if (widthError) {errors.width = widthError}
    const heightError = heightValidator(values.height)
    if (heightError) {errors.height = heightError}
    
    return errors;
  }, [templates, activeTemplate]);
  
  const onSubmit = useCallback((values: { [ p: string ]: string }) => {
    if (!activeTemplate) return
    const { width, height, name } = values
    const resolution = { width: +width, height: +height };
    dispatch(updateTemplate({ id: activeTemplate?.id, name, resolution }))
  }, [activeTemplate, dispatch]);
  
  const size = useMemo(() => <SizeInputs readonly={isProcessed}/>, [isProcessed])
  
  const data: IUNModalProps = useMemo(() => {
    return {
      onSubmit,
      headerText: 'Редактирование',
      validator,
      initialValue: {
        name: activeTemplate?.name ?? '',
        width: activeTemplate?.resolution.width?.toString() ?? '',
        height: activeTemplate?.resolution.height?.toString() ?? '',
      },
      buttonText: 'Сохранить',
      input: {
        placeholder: 'Название шаблона',
      },
      additionalComponents: [size],
      isProcessed,
    }
  }, [onSubmit, validator, activeTemplate?.name, activeTemplate?.resolution.width, activeTemplate?.resolution.height, size, isProcessed]);
  
  const { modal, close, open } = useUniversalModal(data)
  
  useEffect(() => {
    if (!isFinished) { return; }
    close()
    dispatch(resetPressedState())
  }, [isFinished, close, dispatch]);
  
  return { modal, close, open }
}

export default useEditModal;
