import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import useUniversalModal, { IUNModalProps } from "../useUniversalModal";
import boardNameValidatorOnCreate from "../../../../domain/validators/boardNameValidatorOnCreate";
import useBoards from "../../useBoards";
import { useOrganizer } from "../../../../../organizer/ui/hooks/useOrganizer";
import { createBoard } from "../../../../store/templates/actions";
import useTemplates from "../../useTemplates";
import { AppDispatch } from "../../../../../../store/store";
import { resetPressedState } from "../../../../store/templates/templates";
import Type, { typeOptions } from "./elements/events-selector/type";
import boardDurationValidator from "../../../../domain/validators/boardDurationValidator";
import { EventType } from "../../../../../stream/domain/interfaces/events";
import { BoardTypeEnum } from "../../../../../core/domain/interfaces/supabase/overlay/auxiliary-types";

function useCreateModal() {
  const dispatch = useDispatch<AppDispatch>();
  const { info } = useOrganizer()
  const { boards, isProcessed, isFinished } = useBoards()
  const { activeTemplate } = useTemplates()
  
  const validator = useCallback((values: { [ p: string ]: string | { label: string, value: string } }) => {
    const errors: { [ p: string ]: string | undefined } = {}
    
    if (typeof values.name === 'string') {
      const nameError = boardNameValidatorOnCreate(values.name?.trim(), boards.map(boards => boards.name))
      if (nameError) {errors.name = nameError}
    }
    
    if (typeof values.type === 'object') {
      const durationError = boardDurationValidator(+values.duration)
      if (durationError && values.type.value === 'events') {errors.duration = durationError}
    }
    
    return errors
  }, [boards]);
  
  const onSubmit = useCallback((values: {
    [ p: string ]: string | { label: string, value: string } | { label: string, value: string }[] | undefined
  }) => {
    if (!info) {
      console.warn({ event: 'failed to create category: info is undefined', info });
      return
    }
    
    if (!activeTemplate) {
      console.warn({ event: 'failed to create category: activeTemplate is undefined', activeTemplate });
      return
    }
    
    if (typeof values.name !== 'string') {
      console.warn({ event: 'failed to create category: name is not string', values });
      return;
    }
    
    if ((typeof values.type !== 'object') && (values?.type !== undefined) && (Array.isArray(values?.type))) {
      console.warn({ event: 'failed to create category: type is not object or undefined', values });
      return;
    }
    
    const { name, duration } = values
    const type = (values.type as { label: string, value: BoardTypeEnum } | undefined)?.value
    const events = (values.events as { label: string, value: EventType }[] | undefined)?.map(event => event.value)
    const isEvents = type === 'events';
    
    dispatch(createBoard({
      templateId: activeTemplate.id,
      name: name.trim(),
      version: '5.3.0',
      objects: [],
      type,
      duration: isEvents && duration ? +duration : undefined,
      events: isEvents && events ? events : undefined,
    }));
  }, [activeTemplate, dispatch, info]);
  
  const data: IUNModalProps = useMemo(() => {
    return {
      headerText: 'Новое табло',
      initialValue: {
        name: '',
        type: typeOptions.find(type => type.value === 'static'),
        duration: '4',
      },
      validator,
      buttonText: 'Создать',
      input: {
        placeholder: 'Название табло',
      },
      additionalComponents: [<Type type={'static'}/>],
      onSubmit,
      isProcessed,
    }
  }, [isProcessed, onSubmit, validator]);
  
  const { modal, close, open } = useUniversalModal(data)
  
  useEffect(() => {
    if (!isFinished) { return; }
    close()
    dispatch(resetPressedState())
  }, [close, dispatch, isFinished]);
  
  return { modal, close, open }
}

export default useCreateModal;
