import React, { useCallback, useEffect, useState } from 'react';
import { Button, EmptyButton, Item, LongDivider, ShortDivider, SubItem } from "./styles";
import { useSelector } from "react-redux";
import { IEvent, Text as FabricText, Textbox } from "fabric/fabric-impl";
import Selector from "../../elements/selector";
import Input, { IconWrapper } from "../../elements/input";
import ColorPicker from "../../elements/colorPicker";
import AlignTextLeftIcon from "../../../../../ud-ui/components/icon/align-text-left-icon";
import AlignTextCenterIcon from "../../../../../ud-ui/components/icon/align-text-center-icon";
import AlignTextRightIcon from "../../../../../ud-ui/components/icon/align-text-right-icon";
import SnapToGrid from "../../../../../ud-ui/components/fabric-canvas/addons/snapToGrid";
import layerManager from "../../../../../ud-ui/components/fabric-canvas/addons/layerManager";
import ArrowRightIcon from "../../../../../ud-ui/components/icon/arrow-right-icon";
import { selectFabricManager } from "../../../../store/editor/selectors";
import history from "../../../../../ud-ui/components/fabric-canvas/addons/history";
import UDIcon from "../../../../../ud-ui/components/icon";
import alignment from "../../../../../ud-ui/components/fabric-canvas/addons/alignment/alignment";
import { fabric } from "fabric";
import { round } from "../../../../domain/utils/helpers";

interface IProps {
  text: Textbox | FabricText
}

function Text(props: IProps) {
  const text = props.text
  const manager = useSelector(selectFabricManager);
  const [color, setColor] = React.useState(text.fill?.toString());
  const [fontSize, setFontSize] = React.useState(text.fontSize);
  const [fontWeight, setFontWeight] = React.useState(text.fontWeight);
  const [fontFamily, setFontFamily] = React.useState(text.fontFamily);
  const [scale, setScale] = React.useState(text.scaleY);
  const [position, setPosition] = useState({ x: text.left ?? 0, y: text.top ?? 0 })
  const [size, setSize] = useState({ width: round(text.width ?? 0), height: round(text.height ?? 0) })
  
  useEffect(() => {
    setColor(text.fill?.toString());
    setFontSize(text.fontSize);
    setFontWeight(text.fontWeight);
    setFontFamily(text.fontFamily);
    setScale(text.scaleY)
    setPosition({ x: text.left ?? 0, y: text.top ?? 0 })
    setSize({ width: round(text.width ?? 0), height: round(text.height ?? 0) })
    text.setControlsVisibility({ tl: false, tr: false, br: false, bl: false })
    
    const onScale = (e: IEvent<Event>) => {
      if (e.transform?.corner === 'ml') {
        if (text.width === undefined || text.left === undefined) return
        let tl = SnapToGrid.roundByGrid(text.left ?? 0);
        if (!tl) return
        text.set({ left: tl, width: text.width + text.left - tl })
      }
      
      if (e.transform?.corner === 'mr') {
        const width = text.width ?? 0
        text.set({ width: SnapToGrid.roundByGrid(width), scaleX: 1 })
      }
    }
    
    const updateScaling = () => {
      setScale(text.scaleY ?? 1)
    }
    
    let updatePosition = () => {
      const x = text?.left ?? 0
      const y = text?.top ?? 0
      setPosition({ x: round(x), y: round(y) })
    };
    
    let updateSize = () => {
      const { width, height } = text?.getBoundingRect(true) ?? defaultBounds
      setSize({ width: round(width), height: round(height) })
    };
    
    manager?.canvas.on('mouse:up', updatePosition)
    manager?.canvas.on('mouse:up', updateSize)
    text.on('resizing', onScale)
    text.on('scaling', updateScaling)
    return () => {
      text.off('resizing', onScale)
      text.off('scaling', updateScaling)
      manager?.canvas.off('mouse:up', updatePosition)
      manager?.canvas.off('mouse:up', updateSize)
    }
  }, [manager?.canvas, text])
  
  const onChangeColor = (color: string) => {
    setColor(color)
    text.set({ fill: color })
    manager?.render()
    history.saveState()
  }
  
  const onChangeFontSize = (value: number) => {
    if (isNaN(value)) return
    setFontSize(value)
    text.set({ fontSize: value })
    manager?.render()
  }
  
  const onChangeFontFamily = (value: string) => {
    setFontFamily(value)
    text.set({ fontFamily: value })
    manager?.render()
  }
  const onChangeFontWeight = (value: string) => {
    setFontWeight(value)
    text.set({ fontWeight: value })
    manager?.render()
  }
  
  const onChangeTextAlign = (value: string) => {
    text.set({ textAlign: value })
    manager?.render()
  }
  
  const onClickAlignmentCenterVertical = () => {
    const newPos = alignment.single.centerVertical(text)
    if (!newPos) return
    setPosition({ x: newPos.left, y: newPos.top })
  }
  
  const onClickAlignmentCenterHorizontal = () => {
    const newPos = alignment.single.centerHorizontal(text)
    if (!newPos) return
    setPosition({ x: newPos.left, y: newPos.top })
  }
  
  const onFocusField = useCallback(() => {
    manager?.render()
    history.saveState()
  }, [manager])
  
  const onChangePosition = useCallback((x: number, y: number) => {
    text.setPositionByOrigin(new fabric.Point(x, y), 'left', 'top')
    setPosition({ x: round(x), y: round(y) })
    manager?.render()
  }, [manager, text]);
  
  const onChangeSize = useCallback((width: number, height: number) => {
    text.set({ scaleX: width / (text?.width ?? 1), scaleY: height / (text?.height ?? 1) })
    setSize({ width: round(width), height: round(height) })
    SnapToGrid.transformAfterScaling(text)
    manager?.render()
  }, [manager, text]);
  
  return (
    <>
      <Item>
        <div>Текст</div>
      </Item>
      <ShortDivider/>
      <Item>
        <SubItem style={{ width: '26%', justifyContent: 'space-between' }}>
          <EmptyButton onClick={onClickAlignmentCenterHorizontal}>
            <UDIcon name={'align-center-horizontal'}/>
          </EmptyButton>
          <EmptyButton onClick={onClickAlignmentCenterVertical}>
            <UDIcon name={'align-center-vertical'}/>
          </EmptyButton>
        </SubItem>
        <SubItem>
          <span>Расположение</span>
          <Input
            onFocus={onFocusField}
            style={{ width: 73 }}
            icon={<IconWrapper>X</IconWrapper>}
            value={position.x.toString()}
            onChange={(value) => onChangePosition(+value, position.y)}
          />
          <Input
            onFocus={onFocusField}
            style={{ width: 73 }}
            icon={<IconWrapper>Y</IconWrapper>}
            value={position.y.toString()}
            onChange={(value) => onChangePosition(position.x, +value)}
          />
        </SubItem>
        <SubItem>
          <span>Размер</span>
          <Input
            onFocus={onFocusField}
            icon={<IconWrapper>Ш</IconWrapper>}
            value={size.width.toString()}
            onChange={(value) => onChangeSize(+value, size.height)}
          />
          <Input
            onFocus={onFocusField}
            icon={<IconWrapper>В</IconWrapper>}
            value={size.height.toString()}
            onChange={(value) => onChangeSize(size.width, +value)}
          />
        </SubItem>
        <LongDivider/>
        <span>Тип шрифта</span>
        <Selector
          styledEachOption
          onChange={(event) => {onChangeFontFamily((event?.value) ?? '')}}
          options={fontOptions}
          defaultValue={fontOptions.find((option) => option.value === fontFamily)}
        />
        <SubItem style={{ display: 'flex', gap: 8, justifyContent: 'space-between' }}>
          <Selector
            className={'w-100'}
            onChange={(event) => {onChangeFontWeight((event?.value) ?? '')}}
            options={fontWeightsOptions}
            defaultValue={fontWeightsOptions.find((option) => option.value === fontWeight)}
          />
          <Input
            style={{ width: 55 }}
            unit='px'
            onChange={(value) => {onChangeFontSize(+value)}}
            value={fontSize?.toString() ?? '20'}
          />
        </SubItem>
      </Item>
      <LongDivider/>
      <Item>
        <span>Цвет шрифта</span>
        <ColorPicker color={color?.toUpperCase() ?? '#000000'} onChange={onChangeColor}/>
      </Item>
      <LongDivider/>
      <Item style={{ flexDirection: 'row', gap: 8, justifyContent: 'left' }}>
        <EmptyButton onClick={() => {onChangeTextAlign('left') }}><AlignTextLeftIcon/></EmptyButton>
        <EmptyButton onClick={() => {onChangeTextAlign('center') }}><AlignTextCenterIcon/></EmptyButton>
        <EmptyButton onClick={() => {onChangeTextAlign('right') }}><AlignTextRightIcon/></EmptyButton>
        {/*<EmptyButton onClick={() => {onChangeTextAlign('justify') }}><AlignTextJustifyIcon/></EmptyButton>*/}
      </Item>
      <LongDivider/>
      <Item>
        Слои
        <Button onClick={() => layerManager.sendToFront(text)}>
          <ArrowRightIcon height={16} width={16} color={'#929292'} direction={'up'}/> Поднять вверх
        </Button>
        <Button onClick={() => layerManager.sendToBack(text)}>
          <ArrowRightIcon height={16} width={16} color={'#929292'} direction={'down'}/> Переместить вниз
        </Button>
      </Item>
      <Item>
        <SubItem>Размер по Y: {(scale ?? 1).toFixed(4)}</SubItem>
      </Item>
    </>);
}

export default Text;

const fonts = {
  OpenSans: 'Open Sans, sans-serif',
  BigShouldersDisplay: 'Big Shoulders Display, sans-serif',
  Inter: 'Inter, sans-serif',
  TimesNewRoman: 'Times New Roman, serif',
  Arial: 'Arial, sans-serif',
  Georgia: 'Georgia, serif',
  Tahoma: 'Tahoma, sans-serif',
  CourierNew: 'Courier New, monospace',
  BrushScriptMT: 'Brush Script MT, cursive',
}

const fontOptions = Object.entries(fonts).map(font => ({
  value: font[ 1 ],
  label: font[ 0 ],
}));

const fontWeights = {
  Bolder: '900',
  Bold: 'bold',
  Normal: 'normal',
  Lighter: 'lighter',
}
const fontWeightsOptions = Object.entries(fontWeights).map(font => ({ value: font[ 1 ], label: font[ 0 ] }));

const defaultBounds = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
}
