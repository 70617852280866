import { Event } from "../interfaces/events";
import { Fouls, Goals, Team as StateTeam, Teams } from "../interfaces/gameInfo/state";
import { Member } from "../interfaces/gameInfo/gameInfoMapped";
import parseInfoEvent from "../../store/gameInfo/utils/parse-info.event";

export type Player = {
  name?: string
  middleName?: string
  lastName?: string
  number?: number
  avatar?: string
  qtyRedCards?: number
  qtyYellowCards?: number
};

export type Team = {
  name?: string
  logo?: string
  score?: number
  fouls?: number
};

export interface IData {
  players?: {
    first?: Player
    second?: Player
  }
  teams?: {
    team?: Team
    reversedTeam?: Team
  }
  currentPeriod?: number
  eventMinute?: number
}

interface IConvertorData {
  teams?: Teams
  goals: Goals
  fouls: Fouls
}

function convertorEventToReplacer(event: Event, data: IConvertorData): IData {
  const { teams, goals, fouls } = data
  
  let firstPlayer: Member | undefined = undefined
  let secondPlayer: Member | undefined = undefined
  for (const member of [...teams?.first?.members ?? [], ...teams?.second?.members ?? []]) {
    if (member?.id === event?.players?.[ 0 ]?.id) {
      firstPlayer = member
    }
    if (member?.id === event?.players?.[ 1 ]?.id) {
      secondPlayer = member
    }
  }
  
  const parsedTeam = event?.teamId && teams ? parseInfoEvent(event, teams) : undefined
  const parsedReverse = event?.teamId && teams ? parseInfoEvent(event, teams, { teamReverse: true }) : undefined
  
  return {
    players: {
      first: firstPlayer ? convertMember(firstPlayer) : undefined,
      second: secondPlayer ? convertMember(secondPlayer) : undefined,
    },
    teams: {
      team: parsedTeam && parsedTeam.team ?
        convertorTeam({
          team: parsedTeam.team,
          goals: goals[ parsedTeam.teamPrefix ],
          fouls: fouls[ parsedTeam.teamPrefix ],
        }) : undefined,
      reversedTeam: parsedReverse && parsedReverse.team ? convertorTeam({
        team: parsedReverse.team,
        goals: goals[ parsedReverse.teamPrefix ],
        fouls: fouls[ parsedReverse.teamPrefix ],
      }) : undefined,
    },
    currentPeriod: event.period,
    eventMinute: event.minutes,
  }
}

export default convertorEventToReplacer

function convertMember(member: Member): Player {
  return {
    avatar: member.photo,
    name: member.name,
    lastName: member.surname,
    middleName: member.middleName,
    number: member.number,
    qtyRedCards: member.qtyRedCardsMatch,
    qtyYellowCards: member.qtyYellowCardsMatch,
  }
}

function convertorTeam(data: { team: StateTeam, goals: number, fouls: number }): Team {
  const {
    team,
    fouls,
    goals,
  } = data
  
  return {
    score: goals,
    logo: team.logo,
    name: team.name,
    fouls,
  }
}
