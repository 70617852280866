import React from 'react';
import styled from "@emotion/styled";
import { history as historyPage } from "../../../../../../store/store";
import ArrowLeftIcon from "../../../../../ud-ui/components/icon/arrow-left-icon";
import { Pages } from "../../../../../navigation/domain/enums/pages";
import useNotionModal from "../../../hooks/modals/notice";
import { useSelector } from "react-redux";
import { selectHasChange } from "../../../../store/editor/selectors";

function Header() {
  const hasChange = useSelector(selectHasChange)
  
  const onClickBack = () => {
    if (hasChange) {
      open()
    } else {
      historyPage.push(Pages.TEMPLATE.CHOOSING)
    }
  }
  
  const { modal, open } = useNotionModal()
  
  return (
    <MainBlock>
      <Button onClick={onClickBack}><ArrowLeftIcon/> <span>Назад к списку</span></Button>
      {modal}
    </MainBlock>
  );
}

export default Header;

const MainBlock = styled.div`
  display: flex;
  flex-direction: row;
  height: 48px;
  min-height: 48px;
  background: #FDFEFF;
  width: 100%;
  border-bottom: 1px solid #E2E3E4;
  padding-right: 15px;
`

const Button = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  width: 200px;
  padding-right: 20px;
  padding-left: 15px;
  
  
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  
  font-family: Inter, sans-serif;
  color: black;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  gap: 10px;
`
