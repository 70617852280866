import { useCallback, useEffect, useMemo } from "react";
import { useOrganizer } from "../../../../../organizer/ui/hooks/useOrganizer";
import templateNameOnCreateValidator from "../../../../domain/validators/templateNameOnCreateValidator";
import { useDispatch, useSelector } from "react-redux";
import useUniversalModal, { IUNModalProps } from "../useUniversalModal";
import widthValidator from "../../../../domain/validators/width.validator";
import heightValidator from "../../../../domain/validators/height.validator";
import { copyTemplate } from "../../../../store/templates/actions";
import useTemplates from "../../useTemplates";
import { resetPressedState } from "../../../../store/templates/templates";
import SizeInputs from "../template/elements/size/sizeInputs";
import { selectTemplates } from "../../../../store/catalog/selectors";
import { history } from "../../../../../../store/store";
import { Pages } from "../../../../../navigation/domain/enums/pages";
import { addLike } from "../../../../store/catalog/actions";

function useCopyModal(templateId: number) {
  const dispatch = useDispatch()
  const { info } = useOrganizer()
  const template = useSelector(selectTemplates).find(template => template.id === templateId)
  const { templates, isProcessed, isFinished } = useTemplates()
  const validator = useCallback((values: { [ p: string ]: string }) => {
    const errors: { [ p: string ]: string | undefined } = {}
    
    const nameError = templateNameOnCreateValidator(
      values.name?.trim() + ' (копия)',
      templates.map(category => category.name),
    )
    if (nameError) {errors.name = nameError}
    const widthError = widthValidator(values.width)
    if (widthError) {errors.width = widthError}
    const heightError = heightValidator(values.height)
    if (heightError) {errors.height = heightError}
    
    return errors
  }, [templates]);
  
  const onSubmit = useCallback((values: { [ p: string ]: string }) => {
    if (!info) {
      console.warn({ event: 'failed to copy template: info is undefined', info })
      return
    }
    
    const { width, height, name } = values
    const resolution = { width: +width, height: +height };
    dispatch(copyTemplate({ ownerId: info.id, templateId, name: name + ' (копия)', resolution }))
  }, [dispatch, info, templateId]);
  
  const size = useMemo(() => <SizeInputs readonly={isProcessed}/>, [isProcessed])
  
  const data: IUNModalProps = useMemo(() => {
    return {
      onSubmit,
      headerText: 'Название и настройка копии',
      validator,
      initialValue: {
        width: '1920',
        height: '1080',
        name: template?.name ?? '',
      },
      buttonText: 'Создать',
      input: {
        placeholder: 'Название шаблона',
      },
      additionalComponents: [size],
      isProcessed,
    }
  }, [isProcessed, onSubmit, size, template?.name, validator]);
  
  const { modal, close, open } = useUniversalModal(data)
  
  useEffect(() => {
    if (!info) { return; }
    if (!isFinished) { return; }
    close()
    dispatch(resetPressedState())
    dispatch(addLike({ templateId: templateId, userId: info.id }))
    history.push(Pages.TEMPLATE.INDEX, { isCoppedTemplate: true })
  }, [isProcessed, isFinished, close, dispatch, info, templateId])
  
  return { modal, close, open }
}

export default useCopyModal;
