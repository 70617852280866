import React, { useEffect, useMemo } from "react";
import useFabric from "../../../../ud-ui/components/fabric-canvas/hooks/useFabric";
import useReplacer from "../../../../ud-ui/components/fabric-canvas/hooks/useReplacer";
import useTemplates from "../../../../template-editor/ui/hooks/useTemplates";
import { millisToMinutesAndSeconds } from "../../../domain/utils/convertTime";
import { useSelector } from "react-redux";
import useBoards from "../../../../template-editor/ui/hooks/useBoards";
import useTimer from "../../hooks/useTimer";
import { CanvasWrapper } from "./styles";
import { selectActiveBoard } from "../../../../template-editor/store/templates/selectors";
import useGameInfo from "../../hooks/useGameInfo";
import FadeIn from "../../../../ud-ui/components/fabric-canvas/addons/fadeIn";
import useTime from "../../hooks/useTime";
import ReplaceCommon from "../../../../ud-ui/components/fabric-canvas/addons/replacer/replaceCommon";
import ReplaceEvent from "../../../../ud-ui/components/fabric-canvas/addons/replacer/replaceEvent";

export function CanvasStaticBoards() {
  const { canvas, manager } = useFabric('static', { id: "boards" })
  const replacer = useReplacer(manager)
  const { activeTemplate } = useTemplates()
  const { boards, activeScheme } = useBoards()
  const activeBoard = useSelector(selectActiveBoard)
  const { timer } = useTimer()
  const { period } = useTime()
  const gameInfo = useGameInfo()
  const fadeIn = useMemo(() => {
    return new FadeIn()
  }, []);
  const replaceCommon = useMemo(() => {
    return new ReplaceCommon(replacer, fadeIn)
  }, [fadeIn, replacer]);
  const replaceEvent = useMemo(() => {
    return new ReplaceEvent(replacer, fadeIn)
  }, [fadeIn, replacer])
  
  useEffect(() => {
    if (!manager) return
    const curActiveOverlay = activeBoard
    
    const allObject = manager.canvas.getObjects()
    
    fadeIn.fading(allObject).then(() => {
      if (curActiveOverlay?.id !== activeBoard?.id) return
      if (!activeBoard) {
        manager.clear()
        return
      }
      
      if (!boards.length) return
      const scheme = activeScheme
      if (!scheme) return;
      
      const onLoad = () => {
        const loadedObject = manager.canvas.getObjects()
        for (const obj of loadedObject) {
          obj.set({ opacity: 0 })
        }
        
        replaceCommon.replace({
          startOpacity: 0,
          data: {
            ...gameInfo,
            timer: millisToMinutesAndSeconds(timer),
            period,
            championshipName: gameInfo.championship?.name,
            city: gameInfo.match?.address.city,
            organizerLogo: gameInfo.organizer?.logo,
            teams: {
              first: {
                ...gameInfo?.teams?.first,
                lastGamesResults: gameInfo.teams?.first.lastGamesResults.map(x => x.result),
              },
              second: {
                ...gameInfo?.teams?.second,
                lastGamesResults: gameInfo.teams?.second.lastGamesResults.map(x => x.result),
              },
            },
          },
        })
        replaceEvent.replace({ startOpacity: 0 })
        
        const allObject = manager.canvas.getObjects().filter(object => object.opacity === 0)
        for (const obj of allObject) {
          fadeIn.smoothAppearance(obj)
        }
      }
      
      manager.loadLocal({ scheme }).then(onLoad)
    })
    
    // eslint-disable-next-line
  }, [activeBoard, manager, replacer])
  
  useEffect(() => {
    if (!manager) return
    if (!activeTemplate) return
    manager?.setResolution(activeTemplate.resolution)
    manager?.setDimensions(activeTemplate.resolution)
    manager.normalizedViewport()
    fadeIn.init(manager)
  }, [activeTemplate, fadeIn, manager])
  
  useEffect(() => {
    if (!manager) return
    const { goals, fouls } = gameInfo
    
    replacer.updateText({ indicator: 'teamScore0', text: goals.first.toString() })
    replacer.updateText({ indicator: 'teamScore1', text: goals.second.toString() })
    replacer.updateText({ indicator: 'teamFouls0', text: fouls.first.toString() })
    replacer.updateText({ indicator: 'teamFouls1', text: fouls.second.toString() })
    
    replacer.updateText({ indicator: 'teamScore', text: goals.first.toString(), teamIndex: 0 })
    replacer.updateText({ indicator: 'teamScore', text: goals.second.toString(), teamIndex: 1 })
    replacer.updateText({ indicator: 'teamFouls', text: fouls.first.toString(), teamIndex: 0 })
    replacer.updateText({ indicator: 'teamFouls', text: fouls.second.toString(), teamIndex: 1 })
    
    // eslint-disable-next-line
  }, [gameInfo.fouls, gameInfo.goals, replacer])
  
  useEffect(() => {
    if (!manager) return
    replacer.updateText({ indicator: 'matchTime', text: millisToMinutesAndSeconds(timer) })
    replacer.updateText({ indicator: 'period', text: period.toString() })
  }, [replacer, timer, period, manager])
  
  return (
    <CanvasWrapper>
      {canvas}
    </CanvasWrapper>
  );
}
