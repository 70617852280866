import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from "react-redux";
import { Group, Textbox } from "fabric/fabric-impl";
import { fabric } from "fabric";
import { round } from "../../../../domain/utils/helpers";
import { Button, EmptyButton, Item, LongDivider, ShortDivider, SubItem } from "./styles";
import Input, { IconWrapper } from "../../elements/input";
import Selector from "../../elements/selector";
import ColorPicker from "../../elements/colorPicker";
import AlignTextLeftIcon from "../../../../../ud-ui/components/icon/align-text-left-icon";
import AlignTextCenterIcon from "../../../../../ud-ui/components/icon/align-text-center-icon";
import AlignTextRightIcon from "../../../../../ud-ui/components/icon/align-text-right-icon";
import history from "../../../../../ud-ui/components/fabric-canvas/addons/history";
import SnapToGrid from "../../../../../ud-ui/components/fabric-canvas/addons/snapToGrid";
import layerManager from "../../../../../ud-ui/components/fabric-canvas/addons/layerManager";
import ArrowRightIcon from "../../../../../ud-ui/components/icon/arrow-right-icon";
import { selectFabricManager } from "../../../../store/editor/selectors";
import ChoosingTeam from "./components/choosingTeam";
import ChoosingPlayer from "./components/choosingPlayer";
import ChoosingReverse from "./components/choosingReverse";
import UDIcon from "../../../../../ud-ui/components/icon";
import alignment from "../../../../../ud-ui/components/fabric-canvas/addons/alignment/alignment";

interface IProps {
  group: Group;
}

function Placeholder(props: IProps) {
  const { group: placeholder } = props
  const fabricManager = useSelector(selectFabricManager);
  
  const { width, height, top, left } = placeholder.getBoundingRect(true) ?? defaultBounds;
  const type: string = placeholder.data?.type ?? 'img'
  const hasTeam = useMemo(() => {
    const lastChar = placeholder.data?.name?.slice(-1)
    const lastCharIsNum = /^\d+$/.test(lastChar)
    return placeholder.data?.hasTeam || lastCharIsNum || placeholder.data?.teamIndex !== undefined
  }, [placeholder.data?.hasTeam, placeholder.data?.name, placeholder.data?.teamIndex]);
  
  const [teamIndex, setTeamIndex] = useState<0 | 1>((+(placeholder.data?.name?.at(-1) ?? placeholder.data?.teamIndex)) as 0 | 1)
  const [playerIndex, setPlayerIndex] = useState<undefined | 0 | 1>(placeholder?.data?.index ?? placeholder.data?.playerIndex)
  const [reverse, setReverse] = useState<undefined | boolean>(placeholder?.data?.reverse ?? placeholder.data?.teamReverse)
  const [position, setPosition] = useState<{ x: number, y: number }>({ x: left, y: top })
  const [size, setSize] = useState<{ width: number, height: number }>({ width: round(width), height: round(height) })
  
  const [fontColor, setFontColor] = React.useState(placeholder?.data?.color);
  const [fontSize, setFontSize] = React.useState(placeholder?.data?.fontSize);
  const [fontWeight, setFontWeight] = React.useState(placeholder?.data?.fontWeight);
  const [fontFamily, setFontFamily] = React.useState(placeholder?.data?.fontFamily);
  
  useEffect(() => {
    setPosition({ x: round(left), y: round(top) })
    setSize({ width: round(width), height: round(height) })
    setFontColor(placeholder?.data?.color);
    setFontSize(placeholder?.data?.fontSize);
    setFontWeight(placeholder?.data?.fontWeight);
    setFontFamily(placeholder?.data?.fontFamily);
    setTeamIndex((+(placeholder.data?.name?.at(-1) ?? placeholder.data?.teamIndex)) as 0 | 1)
    setPlayerIndex((placeholder?.data?.index ?? placeholder.data?.playerIndex) as undefined | 0 | 1)
    setReverse((placeholder?.data?.reverse ?? placeholder.data?.teamReverse) as undefined | boolean)
    
    let updatePosition = () => {
      const x = placeholder?.get('left') ?? 0
      const y = placeholder?.get('top') ?? 0
      setPosition({ x: round(x), y: round(y) })
    };
    
    let updateSize = () => {
      const { width, height } = placeholder?.getBoundingRect(true) ?? defaultBounds
      setSize({ width: round(width), height: round(height) })
    };
    
    fabricManager?.canvas.on('mouse:up', updatePosition)
    fabricManager?.canvas.on('mouse:up', updateSize)
    
    return () => {
      fabricManager?.canvas.off('mouse:up', updatePosition)
      fabricManager?.canvas.off('mouse:up', updateSize)
    }
    
    // eslint-disable-next-line
  }, [placeholder])
  
  const onChangePosition = (x: number, y: number) => {
    placeholder.setPositionByOrigin(new fabric.Point(x, y), 'left', 'top')
    setPosition({ x: round(x), y: round(y) })
    fabricManager?.render()
  }
  
  const onChangeSize = (width: number, height: number) => {
    placeholder.set({ scaleX: width / (placeholder?.width ?? 1), scaleY: height / (placeholder?.height ?? 1) })
    setSize({ width: round(width), height: round(height) })
    SnapToGrid.transformAfterScaling(placeholder)
    fabricManager?.render()
  }
  
  const onChangeFontColor = (color: string) => {
    setFontColor(color)
    const text = placeholder.getObjects()[ 0 ] as Textbox
    if (!text) return
    if (placeholder.data?.color === undefined) return
    placeholder.data = { ...placeholder.data, color }
    text.set("fill", color)
    fabricManager?.render()
    history.saveState()
  }
  
  const onChangeFontSize = (value: number) => {
    if (isNaN(value)) return
    setFontSize(value)
    if (placeholder.data?.fontSize === undefined) return
    placeholder.data = { ...placeholder.data, fontSize: value }
    fabricManager?.render()
    history.saveState()
  }
  
  const onChangeFontFamily = (value: string) => {
    setFontFamily(value)
    const text = placeholder.getObjects()[ 0 ] as Textbox
    if (!text) return
    if (placeholder.data?.fontFamily === undefined) return
    placeholder.data = { ...placeholder.data, fontFamily: value }
    text.set("fontFamily", value)
    SnapToGrid.transformAfterScaling(placeholder)
    fabricManager?.render()
    history.saveState()
  }
  const onChangeFontWeight = (value: string) => {
    setFontWeight(value)
    const text = placeholder.getObjects()[ 0 ] as Textbox
    if (!text) return
    if (placeholder.data?.fontWeight === undefined) return
    placeholder.data = { ...placeholder.data, fontWeight: value }
    text.set("fontWeight", value)
    SnapToGrid.transformAfterScaling(placeholder)
    fabricManager?.render()
    history.saveState()
  }
  
  const onChangeTextAlign = (value: string) => {
    const text = placeholder.getObjects()[ 0 ] as Textbox
    if (!text) return;
    if (placeholder.data?.textAlign === undefined) return;
    placeholder.data = { ...placeholder.data, textAlign: value }
    text.set("textAlign", value)
    fabricManager?.render()
    history.saveState()
  }
  
  const onFocusField = () => {
    fabricManager?.render()
    history.saveState()
  }
  
  const onChangeTeam = useCallback((teamIndex: 0 | 1) => {
    const oldTeamIndex = placeholder.data?.teamIndex as 0 | 1 | undefined
    if (oldTeamIndex !== undefined) {
      placeholder.data = { ...placeholder.data, teamIndex }
    } else {
      const name = placeholder.data?.name
      placeholder.data = { ...placeholder.data, name: name.slice(0, -1) + teamIndex }
      const type = placeholder.data?.type
      let text: Textbox | undefined
      switch (type) {
        case 'text': {
          text = placeholder.getObjects()[ 0 ] as Textbox | undefined
          break
        }
        case 'image': {
          text = placeholder.getObjects()[ 1 ] as Textbox | undefined
          break;
        }
      }
      
      if (text?.text) {
        const textName = text.text.slice(0, -1) + (teamIndex + 1)
        text?.set({ text: textName })
      }
    }
    
    fabricManager?.render()
    history.saveState()
    setTeamIndex(teamIndex)
  }, [fabricManager, placeholder]);
  
  const onChangePlayer = useCallback((index: 0 | 1) => {
    const oldPlayerIndex = placeholder.data?.playerIndex as 0 | 1 | undefined
    
    if (oldPlayerIndex !== undefined) {
      placeholder.data = { ...placeholder.data, playerIndex: index }
    } else {
      placeholder.data = { ...placeholder.data, index: index }
    }
    
    setPlayerIndex(index)
    fabricManager?.render()
    history.saveState()
  }, [fabricManager, placeholder]);
  
  const onChangeReverse = useCallback((isReverse: boolean) => {
    const oldTeamReverse = placeholder.data?.teamReverse
    
    if (oldTeamReverse !== undefined) {
      placeholder.data = { ...placeholder.data, teamReverse: isReverse }
    } else {
      placeholder.data = { ...placeholder.data, reverse: isReverse }
    }
    
    setReverse(isReverse)
    fabricManager?.render()
    history.saveState()
  }, [fabricManager, placeholder]);
  
  const onClickAlignmentCenterVertical = () => {
    const newPos = alignment.single.centerVertical(placeholder)
    if (!newPos) return
    setPosition({ x: newPos.left, y: newPos.top })
  }
  
  const onClickAlignmentCenterHorizontal = () => {
    const newPos = alignment.single.centerHorizontal(placeholder)
    if (!newPos) return
    setPosition({ x: newPos.left, y: newPos.top })
  }
  
  const isDev = process.env.NODE_ENV === 'development'
  const showDebugInfo = true;
  
  return (
    <>
      <Item>
        <div>Плейсхолдер</div>
      </Item>
      <ShortDivider/>
      <Item>
        <SubItem style={{ width: '26%', justifyContent: 'space-between' }}>
          <EmptyButton onClick={onClickAlignmentCenterHorizontal}>
            <UDIcon name={'align-center-horizontal'}/>
          </EmptyButton>
          <EmptyButton onClick={onClickAlignmentCenterVertical}>
            <UDIcon name={'align-center-vertical'}/>
          </EmptyButton>
        </SubItem>
        <SubItem>
          <span>Расположение</span>
          <Input
            onFocus={onFocusField}
            style={{ width: 73 }}
            icon={<IconWrapper>X</IconWrapper>}
            value={position.x.toString()}
            onChange={(value) => onChangePosition(+value, position.y)}
          />
          <Input
            onFocus={onFocusField}
            style={{ width: 73 }}
            icon={<IconWrapper>Y</IconWrapper>}
            value={position.y.toString()}
            onChange={(value) => onChangePosition(position.x, +value)}
          />
        </SubItem>
        <SubItem>
          <span>Размер</span>
          <Input
            onFocus={onFocusField}
            icon={<IconWrapper>Ш</IconWrapper>}
            value={size.width.toString()}
            onChange={(value) => onChangeSize(+value, size.height)}
          />
          <Input
            onFocus={onFocusField}
            icon={<IconWrapper>В</IconWrapper>}
            value={size.height.toString()}
            onChange={(value) => onChangeSize(size.width, +value)}
          />
        </SubItem>
      </Item>
      {hasTeam && (
        <>
          <LongDivider/>
          <Item>
            <ChoosingTeam onChange={onChangeTeam} value={teamIndex}/>
          </Item>
        </>
      )}
      {((playerIndex !== undefined) || (reverse !== undefined)) && (
        <>
          <LongDivider/>
          {playerIndex !== undefined && (
            <Item>
              <ChoosingPlayer onChange={onChangePlayer} value={playerIndex}/>
            </Item>
          )}
          {reverse !== undefined && (
            <Item>
              <ChoosingReverse onChange={onChangeReverse} value={reverse}/>
            </Item>
          )}
        </>
      )}
      <LongDivider/>
      {type === 'text' && (
        <>
          <Item>
            <div>Текст</div>
          </Item>
          <ShortDivider/>
          <Item>
            <span>Тип шрифта</span>
            <Selector
              onFocus={onFocusField}
              styledEachOption
              onChange={(event) => {onChangeFontFamily((event?.value) ?? '')}}
              options={fontOptions}
              value={fontOptions.find((option) => option.value === fontFamily)}
            />
            <SubItem style={{ display: 'flex', gap: 8, justifyContent: 'space-between' }}>
              <Selector
                onFocus={onFocusField}
                className={'w-100'}
                onChange={(event) => {onChangeFontWeight((event?.value) ?? '')}}
                options={fontWeightsOptions}
                value={fontWeightsOptions.find((option) => option.value === fontWeight)}
              />
              <Input
                onFocus={onFocusField}
                style={{ width: 55 }}
                unit='px'
                onChange={(value) => {onChangeFontSize(+value)}}
                value={fontSize?.toString() ?? '20'}
              />
            </SubItem>
          </Item>
          <LongDivider/>
          <Item>
            <span>Цвет шрифта</span>
            <ColorPicker color={fontColor?.toUpperCase() ?? '#000000'} onChange={onChangeFontColor}/>
          </Item>
          <LongDivider/>
          <Item style={{ flexDirection: 'row', gap: 8, justifyContent: 'left' }}>
            <EmptyButton onClick={() => {onChangeTextAlign('left') }}><AlignTextLeftIcon/></EmptyButton>
            <EmptyButton onClick={() => {onChangeTextAlign('center') }}><AlignTextCenterIcon/></EmptyButton>
            <EmptyButton onClick={() => {onChangeTextAlign('right') }}><AlignTextRightIcon/></EmptyButton>
            {/*<EmptyButton onClick={() => {onChangeTextAlign('justify') }}><AlignTextJustifyIcon/></EmptyButton>*/}
          </Item>
        </>
      )}
      <Item>
        Слои
        <Button onClick={() => layerManager.sendToFront(placeholder)}>
          <ArrowRightIcon height={16} width={16} color={'#929292'} direction={'up'}/> Поднять вверх
        </Button>
        <Button onClick={() => layerManager.sendToBack(placeholder)}>
          <ArrowRightIcon height={16} width={16} color={'#929292'} direction={'down'}/> Переместить вниз
        </Button>
      </Item>
      {isDev && showDebugInfo && (
        <Item>
          scale: <span style={{ whiteSpace: 'pre' }}>
          {JSON.stringify(
            { ...placeholder.getObjectScaling() },
            null,
            2,
          )}
        </span>
          <br/>
          data: <span style={{ whiteSpace: 'pre' }}>{JSON.stringify(placeholder.data, null, 2)}</span>
        </Item>
      )}
    </>
  );
}

export default Placeholder;

const defaultBounds = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
}

const fonts = {
  OpenSans: 'Open Sans, sans-serif',
  BigShouldersDisplay: 'Big Shoulders Display, sans-serif',
  Inter: 'Inter, sans-serif',
  Lato: 'Lato, sans-serif',
  'Nunito Sans': 'Nunito Sans, sans-serif',
  Poppins: 'Poppins, sans-serif',
  Roboto: 'Roboto, sans-serif',
  'Source Sans Pro': 'Source Sans Pro, sans-serif',
  'Ubuntu': 'Ubuntu, sans-serif',
}

const fontOptions = Object.entries(fonts).map(font => ({ value: font[ 1 ], label: font[ 0 ] }));

const fontWeights = {
  Bolder: '900',
  Bold: 'bold',
  Normal: 'normal',
  Lighter: 'lighter',
}
const fontWeightsOptions = Object.entries(fontWeights).map(font => ({ value: font[ 1 ], label: font[ 0 ] }));
