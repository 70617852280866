/** @jsxImportSource @emotion/react */
import UDText from "../../../../../../../ud-ui/components/text";
import { DivProps } from "../../../../../../../../typings";
import { RowHeaderCss, RowWrapper } from "./styles";

interface IProps extends DivProps {
  headerText: string
}

function Row(props: IProps) {
  const { headerText, children, ...rest } = props
  
  return (
    <RowWrapper {...rest}>
      <UDText css={RowHeaderCss} type={'subhead'}>{headerText}</UDText>
      {children}
    </RowWrapper>
  );
}

export default Row;
