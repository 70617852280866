import { useEffect, useMemo } from "react";
import { syncGameInfo } from "../../store/gameInfo/actions";
import { fetchTemplates } from "../../../template-editor/store/templates/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import useGameInfo from "./useGameInfo";
import { setActiveBoard, setActiveTemplate } from "../../../template-editor/store/templates/templates";
import { useQueryParam } from "use-query-params";
import { TokenStorage } from "../../../core/tokenStorage";
import { useParams } from "react-router-dom";
import supabase from "../../../core/supabase/supabase";
import { syncTime } from "../../store/time/actions";
import useTemplates from "../../../template-editor/ui/hooks/useTemplates";
import { getCurrentBoard } from "../../domain/utils/getCurrentBoard";
import { selectIsBoardLocked } from "../../store/overlay/selectors";
import { parseEvent, setFouls, setGoals } from "../../store/gameInfo/gameInfo";
import { addEvents } from "../../store/overlay/overlay";
import { setPeriod, setTimer } from "../../store/time/time";
import { Event } from "../../domain/interfaces/events";
import { selectPeriod } from "../../store/time/selectors";
import { getFouls } from "../../domain/utils/getFouls";
import { Score } from "../../../core/supabase/tables/match-chronology";

function useInitOverlay() {
  const dispatch = useDispatch<AppDispatch>()
  const { matchId: id } = useParams()
  
  const matchId = useMemo(() => id ? parseInt(id) : 0, [id]);
  const encodeToken = useQueryParam('token')[ 0 ] as string | undefined
  const { organizer, championship } = useGameInfo()
  const { isLoaded } = useTemplates()
  const isBoardLocked = useSelector(selectIsBoardLocked)
  const statePeriod = useSelector(selectPeriod)
  
  useEffect(() => {
    if (!encodeToken) return
    const token = window.atob(encodeToken)
    const tokenStorage = new TokenStorage()
    tokenStorage.saveToken(token)
  }, [encodeToken]);
  
  useEffect(() => {
    dispatch(syncGameInfo({ matchId }))
    dispatch(syncTime({ matchId }))
  }, [dispatch, matchId])
  
  useEffect(() => {
    if (!organizer) return
    if (isLoaded) return;
    dispatch(fetchTemplates({ tournamentId: organizer.id }))
  }, [dispatch, isLoaded, organizer]);
  
  useEffect(() => {
    if (!championship?.overlayId) return
    dispatch(setActiveTemplate({ id: championship.overlayId }))
  }, [championship?.overlayId, dispatch]);
  
  useEffect(() => {
    supabase.matchChronology.observeChanges(matchId)
    
    return () => {
      supabase.matchChronology.stopObserveChanges()
    }
  }, [dispatch, matchId])
  
  useEffect(() => {
    const eventsCallback = (newEvents: Event[]) => {
      dispatch(parseEvent({ events: newEvents }))
      dispatch(addEvents(newEvents));
    };
    
    const scoreCallback = (score: Score | null) => {
      if (!score) return
      dispatch(setGoals({ first: score[ 0 ].score, second: score[ 1 ].score }))
    }
    
    supabase.matchChronology.events.subscribe(eventsCallback)
    supabase.matchChronology.score.subscribe(scoreCallback)
    
    
    return () => {
      supabase.matchChronology.events.unsubscribe(eventsCallback)
      supabase.matchChronology.score.unsubscribe(scoreCallback)
    }
  }, [dispatch]);
  
  useEffect(() => {
    const activeBoardCallback = (id: number | null) => {
      if (isBoardLocked) return
      dispatch(setActiveBoard({ id: id ?? undefined }));
    };
    
    supabase.matchChronology.activeBoardId.subscribe(activeBoardCallback)
    
    return () => {
      supabase.matchChronology.activeBoardId.unsubscribe(activeBoardCallback)
    }
  }, [dispatch, isBoardLocked]);
  
  useEffect(() => {
    
    const timeCallback = ({ seconds, period }: { seconds: number; period: number }) => {
      dispatch(setTimer({ milliseconds: seconds * 1000 }));
      dispatch(setPeriod(period));
      if (statePeriod !== period) {
        getFouls(matchId).then((fouls) => {
          dispatch(setFouls(fouls))
        })
      }
    };
    
    supabase.matchChronology.currentTime.subscribe(timeCallback)
    
    return () => {
      supabase.matchChronology.currentTime.unsubscribe(timeCallback)
    }
  }, [dispatch, matchId, statePeriod])
  
  useEffect(() => {
    if (!isLoaded) return
    getCurrentBoard(matchId).then(currentBoard => {
      dispatch(setActiveBoard({ id: currentBoard }))
    })
  }, [dispatch, matchId, isLoaded]);
}

export default useInitOverlay;
