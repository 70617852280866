import { Common } from "./common";
import Interactive from "../../canvases/interactive";
import { Complex } from "./complex";
import { Event } from "./event";

export class Placeholders {
  public common!: Common;
  public complex!: Complex;
  public event!: Event;
  
  constructor(manager: Interactive) {
    this.common = new Common({ manager })
    this.complex = new Complex({ manager })
    this.event = new Event({ manager })
  }
}
