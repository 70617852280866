import Static from "../canvases/static";
import { Object, StaticCanvas } from "fabric/fabric-impl";
import { time } from "../../../../stream/domain/constants/time";

class FadeIn {
  private manager!: Static
  private canvas!: StaticCanvas;
  
  init(manager: Static) {
    this.manager = manager
    this.canvas = manager.canvas
  }
  
  fading(objects: Object[], duration = time.second / 2): Promise<boolean> {
    return new Promise((resolve) => {
      if (objects.length <= 0) { return resolve(false); }
      const promiseAll: Promise<void>[] = []
      for (const object of objects) {
        promiseAll.push(new Promise<void>((resolve) => {
          object.animate(
            'opacity',
            0,
            {
              duration,
              onChange: this.canvas.renderAll.bind(this.canvas),
              onComplete: () => {
                this.canvas.remove(object)
                resolve()
              },
            },
          )
        }))
      }
      
      Promise.all(promiseAll).then(() => {
        resolve(true)
      })
    })
  }
  
  smoothAppearance = (object: Object, duration = time.second / 2): Promise<void> => {
    return new Promise(resolve => {
      object.animate(
        'opacity',
        1,
        {
          from: 0,
          duration,
          onChange: this.canvas.renderAll.bind(this.canvas),
          onComplete: resolve,
        },
      )
    })
  }
  
  appearance(objects: Object[], duration = time.second / 2) {
    return new Promise((resolve) => {
      if (objects.length <= 0) { return resolve(false); }
      const promiseAll: Promise<void>[] = []
      for (const object of objects) {
        promiseAll.push(this.smoothAppearance(object, duration))
      }
      
      Promise.all(promiseAll).then(() => {
        resolve(true)
      })
    })
  }
}

export default FadeIn
