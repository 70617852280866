/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import styled from "@emotion/styled";
import { EmptyButton } from "../components/editor/rightToolbar/styles";
import ArrowLeftIcon from "../../../ud-ui/components/icon/arrow-left-icon";
import { css } from "@emotion/react";
import useFabric from "../../../ud-ui/components/fabric-canvas/hooks/useFabric";
import useReplacer from "../../../ud-ui/components/fabric-canvas/hooks/useReplacer";
import { useSelector } from "react-redux";
import image from '../../../ud-ui/components/images/placeholders/background.png'
import { history } from "../../../../store/store";
import { Pages } from "../../../navigation/domain/enums/pages";
import backgroundElems from "../../../ud-ui/components/fabric-canvas/addons/backgroundElems";
import { selectPreviewState } from "../../store/editor/selectors";
import replaceFakeData from "../../domain/utils/replaceFakeData";

function BoardPreview() {
  const state = useSelector(selectPreviewState)
  const { canvas, manager } = useFabric('static', { data: state?.resolution })
  const replacer = useReplacer(manager)
  
  if (state?.scheme === undefined) {
    history.push(Pages.TEMPLATE.EDITOR)
  }
  
  useEffect(() => {
    if (!manager) return
    if (!state?.scheme) return
    const paramsForBorder = { color: '#F5D956', width: 2, dashArray: true }
    
    manager.loadLocal({ scheme: state.scheme })
      .then(() => {
          backgroundElems.drawBorder(manager.canvas, manager.resolution.value, paramsForBorder)
          
          replaceFakeData({ replacer })
          
          backgroundElems.border?.bringToFront()
        },
      )
    
    manager.canvas.setWidth(window.innerWidth)
    manager.canvas.setHeight(window.innerHeight - 88)
    const offset = { zoom: 0.999 };
    manager.normalizedViewport(offset)
    
    const resizing = () => {
      manager.canvas.setWidth(window.innerWidth)
      manager.canvas.setHeight(window.innerHeight - 88)
      backgroundElems.drawBorder(manager.canvas, manager.resolution.value, paramsForBorder)
      manager.normalizedViewport(offset)
    }
    
    window.addEventListener('resize', resizing)
    
    return () => {
      window.removeEventListener('resize', resizing)
    }
  }, [manager, replacer, state?.scheme])
  
  const onBackToEditor = () => {
    history.push(Pages.TEMPLATE.EDITOR)
  }
  
  return (
    <MainBlock>
      <Header>
        <EmptyButton onClick={onBackToEditor} css={buttonStyle}>
          <ArrowLeftIcon/>
          Назад
        </EmptyButton>
      </Header>
      <CanvasWrapper>
        {canvas}
      </CanvasWrapper>
    </MainBlock>
  );
}

export default BoardPreview;

const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`

const Header = styled.div`
  display: flex;
  padding: 30px;
  background-color: #1A1A29;
  height: 88px;
`

const buttonStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  gap: 10px;
  background-color: #FFFFFF;
  padding: 4px 10px;
  border-radius: 6px;
  
  font-family: Inter, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #000000;
`

const CanvasWrapper = styled.div`
  width: 100vw;
  height: 100%;
  
  background: url("${image}") no-repeat center center fixed;
  background-size: cover;
  overflow: hidden;
`
