/** @jsxImportSource @emotion/react */
import UDTooltip from "../../../../../../ud-form/components/tooltip";
import { ModalButton, tooltipStyles } from "../styles";
import React, { useEffect, useMemo, useState } from "react";
import RectanglePlusIcon from "../../../../../../ud-ui/components/icon/rectangle-plus-icon";
import BaseModal from "../../../elements/modals/baseModal";
import history from "../../../../../../ud-ui/components/fabric-canvas/addons/history";
import { useDispatch, useSelector } from "react-redux";
import { ModalState } from "../../../../../domain/interface/modalState";
import { selectActiveModal, selectFabricManager } from "../../../../../store/editor/selectors";
import { setActiveModal } from "../../../../../store/editor/editor";
import { placeholderList } from "./placeholder-list";
import { complexPlaceholdersList } from "./complex-placeholders-list";
import { eventPlaceholderList } from "./event-placeholder-list";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import { selectActiveBoard } from "../../../../../store/templates/selectors";
import { categoryList } from "./category-list";
import ArrowBracketIcon from "../../../../../../ud-ui/components/icon/arrow-bracket-icon";
import EmptyButton from "../../../../../../ud-ui/components/emptyButton";
import SnapToGrid from "../../../../../../ud-ui/components/fabric-canvas/addons/snapToGrid";
import { Placeholders } from "../../../../../../ud-ui/components/fabric-canvas/addons/placeholders";
import { Placeholder } from "../../../../../../ud-ui/components/fabric-canvas/types/placeholders/createArgument";


export function AddPlaceholder() {
  const dispatch = useDispatch()
  const activeBoard = useSelector(selectActiveBoard)
  const { getData } = useLocalStorage()
  const activeModal = useSelector(selectActiveModal);
  const fabricManager = useSelector(selectFabricManager);
  const [isSelected, setSelected] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string>()
  const [categoryModalState, setCategoryModalState] = useState({ isModalOpen: false, modalStyle: {} });
  const [modalPlaceholderState, setModalPlaceholderState] = useState<ModalState>({
    isModalOpen: false,
    modalStyle: {},
  });
  const boardType = useMemo(() => {
    const { boardType } = getData()
    return boardType
  }, [getData]);
  const placeholders = useMemo(() => {
    if (!fabricManager) return
    return new Placeholders(fabricManager)
  }, [fabricManager]);
  
  useEffect(() => {
    const clickListener = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      if (!target) return
      if (target.id.includes('categoryButton')) return
      
      if (isSelected) {
        setSelected(false)
        setModalPlaceholderState((prev) => ({ ...prev, isModalOpen: false }))
        setCategoryModalState((prev) => ({ ...prev, isModalOpen: false }))
      }
    };
    
    const closeModalOnEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setSelected(false)
        setCategoryModalState((prev) => ({ ...prev, isModalOpen: false }))
        setModalPlaceholderState((prev) => ({ ...prev, isModalOpen: false }))
        dispatch(setActiveModal(undefined))
      }
    }
    
    document.addEventListener('keydown', closeModalOnEsc)
    document.addEventListener('click', clickListener)
    return () => {
      document.removeEventListener('keydown', closeModalOnEsc)
      document.removeEventListener('click', clickListener)
    }
  }, [activeModal, dispatch, isSelected])
  
  useEffect(() => {
    if (activeModal !== 'addPlaceholder') {
      setSelected(false)
      setCategoryModalState((prev) => ({ ...prev, isModalOpen: false }))
      setModalPlaceholderState((prev) => ({ ...prev, isModalOpen: false }))
    }
  }, [activeModal]);
  
  useEffect(() => {
    if (!isSelected && activeModal === 'addPlaceholder') {
      dispatch(setActiveModal(undefined))
    }
    // eslint-disable-next-line
  }, [isSelected]);
  
  const onClickButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isSelected) {
      setCategoryModalState((prev) => ({ ...prev, isModalOpen: false }))
      setModalPlaceholderState((prev) => ({ ...prev, isModalOpen: false }))
      setSelected(false)
      dispatch(setActiveModal(undefined))
      return
    }
    
    const target = e.currentTarget;
    if (!target) return;
    
    const bounding = target.getBoundingClientRect();
    setCategoryModalState(
      {
        isModalOpen: true,
        modalStyle: {
          left: `${bounding.left + bounding.width + 15}px`,
          width: 150,
        },
      },
    )
    setSelected(true)
    dispatch(setActiveModal("addPlaceholder"))
  }
  
  const addPlaceholder = (props: Placeholder) => {
    if (!fabricManager || !placeholders) return
    history.saveState()
    const placeholder = placeholders.common.create(props)
    
    SnapToGrid.snapToGrid([placeholder])
    
    setModalPlaceholderState((prev) => ({ ...prev, isModalOpen: false }))
    setCategoryModalState((prev) => ({ ...prev, isModalOpen: false }))
    dispatch(setActiveModal(undefined))
  }
  
  
  const placeholderButtons = placeholderList.map((placeholder) => {
    const { listName, ...rest } = placeholder
    
    return (
      <ModalButton
        key={placeholder.indicator}
        onClick={() => addPlaceholder(rest)}
      >
        {placeholder.listName}
      </ModalButton>
    )
  })
  
  const complexPlaceholderButtons = complexPlaceholdersList.map((placeholder) => {
    const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      if (!fabricManager || !placeholders) return
      history.saveState()
      placeholders.complex.create(placeholder)
      setModalPlaceholderState((prev) => ({ ...prev, isModalOpen: false }))
      dispatch(setActiveModal(undefined))
    }
    
    return (
      <ModalButton
        key={placeholder.indicator}
        onClick={onClick}
      >
        {placeholder.listName}
      </ModalButton>
    )
  })
  
  const eventsPlaceholderButtons = useMemo(() => {
    return eventPlaceholderList.map((placeholder) => {
      const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        if (!fabricManager || !placeholders) return
        history.saveState()
        const object = placeholders?.event.create(placeholder)
        SnapToGrid.snapToGrid([object])
        
        setModalPlaceholderState((prev) => ({ ...prev, isModalOpen: false }))
        dispatch(setActiveModal(undefined))
      }
      
      return (
        <ModalButton
          key={placeholder.indicator}
          onClick={onClick}
        >
          {placeholder.listName}
        </ModalButton>
      )
    })
  }, [dispatch, fabricManager]);
  
  const categoryButtons = useMemo(() => {
    const openCategoryModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, category: string) => {
      e.stopPropagation()
      const target = e.currentTarget;
      if (!target) return;
      const bounding = target.getBoundingClientRect();
      setModalPlaceholderState(
        {
          isModalOpen: true,
          modalStyle: {
            top: `${bounding.top - 8}px`,
            left: `${bounding.left + bounding.width + 2}px`,
            width: 300,
          },
        },
      )
      setSelectedCategory(category)
    }
    
    return categoryList
      .filter(category => {
        if ((activeBoard?.type ?? boardType) !== 'events') {
          return category.value !== 'events'
        }
        return true
      })
      .map(category => (
        <ModalButton
          id={'categoryButton'}
          key={category.value}
          onClick={(event) => {openCategoryModal(event, category.value)}}
          style={{ justifyContent: 'space-between' }}
        >
          {category.label}
          <ArrowBracketIcon color={'#8F8F8F'} width={10} height={10} direction={'left'}/>
        </ModalButton>
      ))
  }, []);
  
  
  return (
    <>
      <UDTooltip show={!activeModal} css={tooltipStyles} text={"Блоки"}>
        <EmptyButton
          color={activeModal === 'addPlaceholder' ? '#F5D956' : undefined}
          onClick={onClickButton}
          icon={<RectanglePlusIcon/>}
        />
      </UDTooltip>
      <BaseModal isOpen={categoryModalState.isModalOpen && isSelected} style={categoryModalState.modalStyle}>
        {categoryButtons}
      </BaseModal>
      <BaseModal isOpen={modalPlaceholderState.isModalOpen && isSelected} style={modalPlaceholderState.modalStyle}>
        {selectedCategory === 'common' && placeholderButtons}
        {selectedCategory === 'complex' && complexPlaceholderButtons}
        {selectedCategory === 'events' && eventsPlaceholderButtons}
      </BaseModal>
    </>
  );
}
