import { Scheme } from "../../../ud-ui/components/fabric-canvas/types";
import { useCallback } from "react";

type ISetToLocalStorage = {
  scheme?: Scheme
  boardId?: number,
  resolution?: { width: number, height: number }
  boardType?: string
};

type IGetFromLocalStorage = ISetToLocalStorage & {
  userId?: number
};

export default function useLocalStorage() {
  
  const clearData = useCallback(() => {
    localStorage.removeItem('scheme')
    localStorage.removeItem('resolution')
    localStorage.removeItem('boardId')
    localStorage.removeItem('boardType')
  }, []);
  
  const setData = useCallback(({ scheme, boardId, resolution, boardType }: ISetToLocalStorage) => {
    scheme && localStorage.setItem('scheme', JSON.stringify(scheme))
    resolution && localStorage.setItem('resolution', JSON.stringify(resolution))
    boardId && localStorage.setItem('boardId', boardId.toString())
    boardType && localStorage.setItem('boardType', boardType)
  }, []);
  
  const getData = useCallback((): IGetFromLocalStorage => {
    const scheme = localStorage.getItem('scheme')
    const userId = localStorage.getItem('imgame:activeTournamentId')
    const resolution = localStorage.getItem('resolution')
    const boardId = localStorage.getItem('boardId')
    const boardType = localStorage.getItem('boardType')
    
    const transformUserId = userId ? Number(userId) : undefined
    const transformBoardId = boardId ? Number(boardId) : undefined
    const transformResolution: {
      width: number,
      height: number
    } | undefined = resolution ? JSON.parse(resolution) : undefined
    const transformScheme: Scheme | undefined = scheme ? JSON.parse(scheme) : undefined
    
    return {
      userId: transformUserId,
      boardId: transformBoardId,
      resolution: transformResolution,
      scheme: transformScheme,
      boardType: boardType ?? undefined,
    }
  }, []);
  
  return { setData, getData, clearData }
}
